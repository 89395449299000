.header-trending-card {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 157.143% */
}
.trending-card-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-height: 65.5rem;
  margin-bottom: 0.75rem;
  overflow-y: auto;
  padding: 0.75rem 1.25rem;
  padding-bottom: 0rem !important;
}
.phrase-container {
  display: flex;
  box-sizing: border-box;
  padding-bottom: 0.375rem;
  justify-content: space-between;
  border-bottom: 0.5px solid #d9d9d9;
}
.count-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.up-arrow-color {
  color: #046a38;
}
.down-arrow-color {
  color: #f5222d;
}
.phrase-content {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.count-text {
  font-weight: 600;
  /* width: 1.5rem; */
  text-align: start;
}
.deviation-container {
  display: flex;
  /* gap: 0.5rem; */
  width: 2.3rem;
  justify-content: space-between;
}
.trending-card-content::-webkit-scrollbar {
  /* height: 0.2rem; */
  width: 0.25rem;
}

.trending-card-content::-webkit-scrollbar-thumb {
  background-color: grey;
  /* border-radius: 1.25rem; */
}

.trending-card-content::-webkit-scrollbar-track {
  background-color: transparent;
  /* Make the track transparent */
}

.trending-empty-image {
  width: 12rem;
  height: 12rem;
}

.trending-empty-image-full-page {
  width: 20rem;
  height: 20rem;
}