.upload-heading-box {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  margin-top: 0.5rem;
}
.upload-content {
  color: #141414;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}
.sub-content {
  color: var(--neutral-8, #595959);
  /* margin-top: 0.75rem; */
  margin-top: 0.125rem;
  /* Body/regular */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.upload-form-box {
  /* padding: 0rem 2rem; */
  padding-top: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
}
.form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form-group-col {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 31%;
  font-style: normal;
  font-weight: 400;

  font-size: 1rem;
}
.form-group-col-1 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 48%;
  font-style: normal;
  font-weight: 400;

  font-size: 1rem;
}
.form-group-col-des {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  font-style: normal;
  font-weight: 400;

  font-size: 1rem;
}
.input-box-form {
  border-radius: 0.125rem;
  border: 1.5px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  height: 2.375rem;
  display: flex;
  padding: 0rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.textarea-box-form {
  border-radius: 0.125rem;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  display: flex;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  min-height: 4rem;
  /* height: 5rem; */
  resize: none;
  overflow-y: hidden;
  box-sizing: border-box;
}
.submit-box {
  box-sizing: border-box;
  text-align: end;
  margin-top: 1rem;
}
.submit-button {
  display: inline-flex;
  height: 2.5rem;
  padding: 0.25rem 0.9375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.125rem;
  background: var(--Primary-green--, #92bb45);
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: #fff;
  width: max-content;
  border: none;
  cursor: pointer;
}
.center-align {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.left-side-heading {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.right-side-button-box {
  display: flex;
  gap: 1.5rem;
}
.edit-button {
  border-radius: 0.125rem;
  background: var(--Primary-green--, #92bb45);
  height: 2rem;
  border: 1px solid #92bb45;
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  display: inline-flex;
  padding: 0.25rem 0.9375rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: white;
  cursor: pointer;
}
.delete-button {
  display: inline-flex;
  height: 2rem;
  padding: 0.25rem 0.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.125rem;
  border: 1px solid var(--Primary-green--, #92bb45);
  background: var(--neutral-1, #fff);
  /* drop-shadow/button-secondary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: #92bb45;
  cursor: pointer;
}
.ant-switch-checked {
  background: #92bb45 !important;
}
.textarea-box-form:focus {
  outline: none !important;
}
.textarea-box-form:disabled {
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-4, #f0f0f0);
  cursor: default;
  color: black;
}
.input-box-form:focus-visible {
  outline: none !important;
  background-color: none !important;
}
.input-box-form:disabled {
  cursor: default;
  color: black;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-4, #f0f0f0);
}
.select-box-form {
  max-height: 2rem;
  display: flex;
  width: 100%;
}
.cursor-n {
  cursor: not-allowed;
}
.css-tj5bde-Svg {
  /* color: #2E6A3E !important; */
  opacity: 0.68;
}
.css-wsp0cs-MultiValueGeneric {
  /* color: black !important; */
  color: #2e6a3e !important;
}
.css-9jq23d{
  /* color: black !important; */
  color: #2e6a3e !important;
}

.css-olqui2-singleValue {
  color: black !important;
}
.link {
  color: #007eb3 !important;
}
.css-13cymwt-control {
  border-radius: 0.125rem !important;
}
.time-picker {
  min-height: 2.375rem;
  border-radius: 0.125rem;
  border: 1.5px solid var(--neutral-5, #d9d9d9) !important;
  box-shadow: none !important;
  background: var(--neutral-1, #fff);
  display: flex;
  padding: 0rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.time-picker:hover {
  outline: none;
  border: 1.5px solid var(--neutral-5, #d9d9d9);
}
.time-picker:focus-visible {
  outline: none !important;
  background-color: none !important;
}
.time-picker:disabled {
  cursor: default;
  color: black;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-4, #f0f0f0);
}
.ant-btn-primary {
  color: black;
  background: rgba(146, 187, 69, 0.3);
}
.timepicker-placeholder {
  font-size: 1rem;
  color: hsl(0, 0%, 50%) !important;
  font-weight: 400;
  font-style: normal;
}
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: rgba(146, 187, 69, 0.3) !important;
}
.ant-btn-sm:hover{
  background-color: rgba(146, 187, 69, 0.3) !important;
  color: black !important;  
}
.form-row-3{
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap; 
}